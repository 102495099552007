import { ViewData } from 'cccisd-laravel-assignment';
import React from 'react';

const Component = () => {
    return (
        <ViewData
            projectId={1}
            hideProjectDropdown
            labelForDeployment="Time Point"
            labelForProjectPlural="Programs"
            labelForQuest="Survey"
            labelForSurvey="Section"
        />
    );
};

export default Component;
