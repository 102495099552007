import React from 'react';
import { Link } from 'react-router-dom';
import { LoginForm } from 'cccisd-laravel-nexus';
import _find from 'lodash/find';
import style from './style.css';

var Fortress = window.cccisd.fortress;
var AppDefs = window.cccisd.appDefs;

const Welcome = () => {
    let homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);

    return (
        <div className={style.wrapper}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="landingPage" style={{ marginTop: '20px', width: '75%' }}>
                        <div className="welcomeLogin">
                            <div className="row">
                                <div className="col-md-8">
                                    <div style={{ textAlign: 'center' }}>
                                        <h1>
                                            <b>Welcome to Building Healing Systems</b>
                                        </h1>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    {Fortress.auth() ? (
                                        <div className={style.welcomeBox}>
                                            <p>Hello, {Fortress.user.username}</p>
                                            {homepageRoute && (
                                                <Link to={homepageRoute.url}>
                                                    <button type="button" className="btn btn-primary">
                                                        Go To Home
                                                    </button>
                                                </Link>
                                            )}
                                        </div>
                                    ) : (
                                        <div>{!Fortress.auth() && <LoginForm />}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Welcome;
